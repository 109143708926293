<div class="main-left-nav" [class.collapsed]="!menuExpanded">
    <div class="nav-body">
        <div class="switcher-con">
            <p-dropdown
                [(ngModel)]="selectedChain"
                [options]="blockchainOptions"
                [group]="true"
                [placeholder]="menuExpanded ? 'Select Blockchain' : ''"
                [autoDisplayFirst]="false"
                appendTo="body"
                [style]="{ 'width': '100%', 'min-width': '0px' }"
                styleClass="blockchain-selector"
                panelStyleClass="blockchain-selector-panel"
                (onChange)="changeBlockchain($event)"
            >
                <ng-template let-chain pTemplate="selectedItem">
                    <div *ngIf="menuExpanded"
                        class="dropdown-item selected"
                    >
                        <img *ngIf="chain.icon" [src]="'assets/images/blockchains/' + chain.icon">
                        <div *ngIf="chain.material_icon" class="material-symbol">
                            <span class="material-symbols-filled">{{ chain.material_icon }}</span>
                        </div>

                        <div>{{ chain.label }}</div>
                    </div>

                    <div *ngIf="!menuExpanded"
                        class="dropdown-item selected short"
                    >
                        <img [src]="'assets/images/blockchains/' + chain.icon">
                    </div>
                </ng-template>

                <ng-template let-group pTemplate="group">
                    <div class="flex align-items-center group-label">
                        <span>{{ group.label }}</span>
                    </div>
                </ng-template>

                <ng-template let-chain pTemplate="item">
                    <div class="dropdown-item"
                        [class.link]="chain.link"
                        [pTooltip]="chain.tooltip"
                        tooltipPosition="right"
                    >
                        <img *ngIf="chain.icon" [src]="'assets/images/blockchains/' + chain.icon">
                        <div *ngIf="chain.material_icon" class="material-symbol">
                            <span class="material-symbols-filled">{{ chain.material_icon }}</span>
                        </div>

                        <div>{{ chain.label }}</div>

                        <div *ngIf="chain.coming_soon" class="tag coming-soon">Coming Soon!</div>
                        <div *ngIf="chain.new" class="tag new">New!</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="static-menu">
            <p-menu *ngIf="menuExpanded" [model]="menuItems"></p-menu>

            <div *ngIf="!menuExpanded" class="collapsed-menu">
                <div *ngFor="let item of menuItems" class="item-con">
                    <span *ngIf="item.collapsedIcon"
                        class="material-symbols-outlined parent-icon"
                        [class.pointer]="item.routerLink"
                        [pTooltip]="item.collapsedLabel"
                        tooltipPosition="right"
                        [routerLink]="item.routerLink"
                    >
                        {{ item.collapsedIcon }}
                    </span>

                    <div *ngFor="let child of (item.items || [])"
                        class="child-con {{child.styleClass}}"
                    >
                        <span *ngIf="child.routerLink"
                            class="child-link material-symbols-outlined"
                            [class.pointer]="child.routerLink"
                            [pTooltip]="child.collapsedLabel"
                            tooltipPosition="right"
                            [routerLink]="child.routerLink"
                        >
                            {{ child.collapsedIcon }}
                        </span>

                        <a *ngIf="child.url"
                            [href]="child.url"
                            target="_blank"
                            class="child-link"
                        >
                            <span class="material-symbols-outlined pointer"
                                [pTooltip]="child.collapsedLabel"
                                tooltipPosition="right"
                            >
                                {{ child.collapsedIcon }}
                            </span>
                            <span class="super material-symbols-outlined">
                                arrow_outward
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="nav-footer">
        <div class="help-con" (click)="linkToHelp()">
            <span class="material-symbols-outlined">contact_support</span>
            <span class="help-text">Help</span>
        </div>

        <div class="theme-switcher-con">
            <theme-switcher></theme-switcher>
        </div>

        <div class="login-con">
            <login></login>
        </div>
    </div>
</div>
