import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { exhaustMap } from "rxjs/operators";

import * as LayoutActions from "./layout.actions";


@Injectable()
export class LayoutEffects {
    constructor(
        private actions$: Actions
    ) {}

    showSubmenu$ = createEffect(() => this.actions$.pipe(
        ofType(LayoutActions.ShowSubmenuAction),
        exhaustMap(action => {
            return of(LayoutActions.CollapseMenuAction());
        })
    ));

    // hideSubmenu$ = createEffect(() => this.actions$.pipe(
    //     ofType(LayoutActions.HideSubmenuAction),
    //     exhaustMap(action => {
    //         return of(LayoutActions.ExpandMenuAction());
    //     })
    // ));
}