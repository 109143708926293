import { createReducer, on } from "@ngrx/store";

import * as FractionalizeActions from "./fractionalize.actions";


export interface FractionalizeState {
    showPanel: boolean;
    masterData: any;
    status: any[];
    fractionalData: any;
};

const initialState: FractionalizeState = <FractionalizeState>{
    showPanel: false,
    masterData: null,
    status: [],
    fractionalData: null
};

export const fractionalizeReducer = createReducer(
    initialState,
    on(FractionalizeActions.ToggleSidePanelAction, (state, action) => {
        return Object.assign({}, state, {
            showPanel: action.payload === undefined ? !state.showPanel : action.payload
        });
    }),
    on(FractionalizeActions.SetTokenIdAction, (state, action) => {
        return Object.assign({}, state, {
            tokenId: action.payload
        });
    }),
    on(FractionalizeActions.MintMasterToken, (state, action) => {
        return Object.assign({}, state, {
            masterData: action.payload
        });
    }),
    on(FractionalizeActions.MintMasterTokenSuccess, (state, action) => {
        return Object.assign({}, state, {
            masterData: Object.assign({}, state.masterData, action.payload)
        });
    }),
    on(FractionalizeActions.FetchTokenStatusSuccessAction, (state, action) => {
        return Object.assign({}, state, {
            status: action.payload
        });
    }),
    on(FractionalizeActions.FractionalizeTokenAction, (state, action) => {
        return Object.assign({}, state, {
            fractionalData: action.payload
        });
    }),
    on(FractionalizeActions.FractionalizeTokenSuccessAction, (state, action) => {
        return Object.assign({}, state, {
            fractionalData: Object.assign({}, state.fractionalData, action.payload)
        });
    }),
);
