import { Component, OnInit, Input } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { AppStore } from "../store";
import { BLOCKCHAIN_OPTIONS } from "../shared/utilities/constants";
import { SetBlockChainAction } from "../user/user.actions";
import { getBlockchain } from "../user/user.selectors";


@Component({
    selector: "app-left-nav",
    templateUrl: "./left-nav.component.html",
    styleUrls: ["./left-nav.component.scss"]
})
export class LeftNavComponent implements OnInit {
    @Input() menuExpanded: boolean;

    private subscriptions: Subscription[] = [];
    private lastChain: string;

    public selectedChain: string;
    public blockchainOptions: any[];

    public menuItems: any[];

    constructor(
        private store: Store<AppStore>,
        private router: Router
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe(event => {
                    this.findSelected((event as NavigationEnd).url);
                })
        );

        this.blockchainOptions = [
            // {
            //     items: [
            //         {
            //             label: "Blockchain Information",
            //             value: "info",
            //             material_icon: "hexagon",
            //             link: true
            //         }
            //     ]
            // },
            {
                label: "Private Blockchains",
                value: "private",
                items: BLOCKCHAIN_OPTIONS.filter(chain => !chain.public)
                        .map(chain =>
                            Object.assign({}, chain, {
                                label: chain.name,
                                value: chain.chainId,
                                disabled: !chain.selectable
                            })
                        )
            },
            {
                label: "Public Blockchains",
                value: "public",
                items: BLOCKCHAIN_OPTIONS.filter(chain => chain.public && chain.selectable)
                        .map(chain =>
                            Object.assign({}, chain, {
                                label: chain.name,
                                value: chain.chainId,
                                disabled: !chain.selectable
                            })
                        )
            },
            {
                label: "Custom Blockchain",
                value: "custom",
                items: [
                    {
                        label: "Create Blockchain",
                        value: "create",
                        material_icon: "build",
                        link: true,
                        coming_soon: true,
                        tooltip: "\nCreate your own private or public blockchain\n\nCreating your own private blockchain allows control over governance, security, and data privacy. You can set gas fees, customize tokenomics, and ensure efficient token transactions while limiting access to authorized parties.\n\nContact Us to learn more\n\n"
                    }
                ]
            }
        ];

        this.subscriptions.push(
            this.store.select(getBlockchain).subscribe(chain => {
                this.lastChain = chain;
                this.selectedChain = chain;
            })
        );

        this.menuItems = [
            {
                items: [
                    {
                        label: "<span class='material-symbols-outlined'>laptop_windows</span> Demos",
                        escape: false,
                        collapsedLabel: "Demos",
                        collapsedIcon: "laptop_windows",
                        routerLink: "/demos"
                    }
                ]
            },
            {
                items: [
                    {
                        label: "<span class='material-symbols-outlined'>wallet</span> Foundry",
                        escape: false,
                        collapsedLabel: "Foundry",
                        collapsedIcon: "wallet",
                        routerLink: "/create_token"
                    },
                    {
                        label: "<span class='material-symbols-outlined'>stack_hexagon</span> Tokens",
                        escape: false,
                        collapsedLabel: "Tokens",
                        collapsedIcon: "stack_hexagon",
                        routerLink: "/wallet"
                    }
                ]
            },
            {
                items: [
                    {
                        label: "<span class='material-symbols-outlined'>dashboard_2</span> Admin Dashboard&nbsp;<span class='super material-symbols-outlined'>arrow_outward</span>",
                        escape: false,
                        collapsedLabel: "Admin Dashboard",
                        collapsedIcon: "dashboard_2",
                        url: "https://beta.tokenx.app"
                    // },
                    // {
                    //     label: "<span class='material-symbols-outlined'>developer_guide</span> Developer Documents&nbsp;<span class='super material-symbols-outlined'>arrow_outward</span>",
                    //     escape: false,
                    //     collapsedLabel: "Developer Documents",
                    //     collapsedIcon: "developer_guide",
                    //     url: ""
                    }
                ]
            }
        ];
    }

    findSelected(url: string) {
        this.menuItems.forEach(item => {
            if (item.routerLink) {
                //Check top item
            }

            if (item.items) {
                item.items = item.items.map((subitem: any) => {
                    return Object.assign(subitem, {
                        styleClass: url.startsWith(subitem.routerLink) ? "selected": null
                    });
                });
            }
        });
    }

    changeBlockchain(event: any) {
        if (event.value) {
            switch (event.value) {
                case "info": {
                    // Route to blockchains page
                    this.router.navigate(["blockchains"]);

                    // Set back to previous blockchain
                    window.setTimeout(() => {
                        this.selectedChain = this.lastChain;
                    });
                    break;
                }

                case "create": {
                    // Set back to previous blockchain
                    window.setTimeout(() => {
                        this.selectedChain = this.lastChain;
                    });
                    break;
                }

                default: {
                    this.store.dispatch(SetBlockChainAction(event.value));
                }
            }
        }
    }

    linkToHelp() {
        window.open("https://www.tokenx.is/support/", "_new");
    }
}
