import { Component, OnInit } from "@angular/core";

import BLOCKCHAINS from "./blockchain-info.json";


@Component({
    selector: "blockchains-info",
    templateUrl: "./blockchains.component.html",
    styleUrls: ["./blockchains.component.scss"]
})
export class BlockchainsInfoComponent implements OnInit {
    public cols: any[] = [];
    public chains: any[] = [];

    constructor() {}

    ngOnInit() {
        this.cols = [
            {
                field: "stability",
                header: "Stability",
                containsTag: true
            },
            {
                field: "est_gas_fee",
                header: "Est Gas Fee per transaction",
                containsTag: false
            },
            {
                field: "gas_fees",
                header: "Gas Fees",
                containsTag: true
            },
            {
                field: "adoption",
                header: "Adoption",
                containsTag: true
            },
            {
                field: "transaction_time",
                header: "Transaction Time",
                containsTag: true
            },
            {
                field: "security",
                header: "Security",
                containsTag: true
            }
        ];

        this.chains = BLOCKCHAINS;
    }
}