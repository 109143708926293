import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, firstValueFrom } from "rxjs";
import Web3 from "web3";

import {
    ResponseType,
    IPFS_BASE_URL,
    CONTRACT_ABI_JSON
} from "../utilities/constants";
import { BaseService } from "./base.service";
import { UserService } from "../../user/user.service";


@Injectable()
export class TokenService extends BaseService {
    private web3: any;

    constructor(
        http: HttpClient,
        private userService: UserService
    ) {
        super(http);

        this.web3 = new Web3(window.ethereum);
    }

    uploadRawData(data: any): Observable<any> {
        let url = `api/upload`;
        let method = BaseService.POST;
        
        let payload: any = {
            method,
            url,
            responseType: ResponseType.TEXT,
            body: data,
        }

        if (data.file) {
            payload.files = data.file;
        }
        // Note: might need to convert to formData
        return this.makeRequest(payload);
    }

    uploadMetadata(data: any): Observable<any> {
        let url = `api/metadata`;
        let method = BaseService.POST;

        // Note: might need to convert to formData
        return this.makeRequest({
            method,
            url,
            body: { 
                "nft-name": data["nft-name"],
                "nft-desc": data["nft-desc"],
                "nft-type" : data["token-type"],
                filehash: data["filehash"]
            }
        });
    }

    getContractJSON(): Observable<any> {
        return this.makeRequest({
            url: CONTRACT_ABI_JSON,
            method: BaseService.GET
        });
    }

    async mintClientWallet(metadataHash: string) {
        const contractJson = await firstValueFrom(this.getContractJSON());
        const networkId = await this.web3.eth.net.getId();
        const currentAccount = await this.userService.fetchCurrentAccount();

        //generate token ID
        const tokenId = new Date().getTime()
        var nftContract = new this.web3.eth.Contract(
            contractJson.abi,
            contractJson.networks[networkId].address
        );
        return nftContract.methods
            .safeMint(
                currentAccount, 
                IPFS_BASE_URL + metadataHash,
                tokenId
            ).send({ 
                from: currentAccount
            }).on('transactionHash', function (hash: string) {
                return hash;

            }).on('receipt', function (receipt: string) {
                alert("NFT minted successfully!");
                return receipt;
            });
    }

    mintServerSelfCustody(address: string, metadataHash: string): Observable<any> {
        return this.makeRequest({
            url: "api/safeMint",
            method: BaseService.POST,
            body: {
                "tokenUri": IPFS_BASE_URL + metadataHash,
                "toAddress" : address
            }
        });
    }

    mintCustodian(metadataHash: string): Observable<any> {
        return this.makeRequest({
            url: "api/safeMintCustodian",
            method: BaseService.POST,
            body: {
                "tokenUri": IPFS_BASE_URL + metadataHash
            }
        });
    }

    async getNFTWallet(signCode: string) {
        const currentAccount = await this.userService.fetchCurrentAccount();
        const msg = `Sign Code: ${signCode}`;
        const signed = await this.web3.eth.personal.sign(msg, currentAccount);

        // console.log("Current Account:", currentAccount);
        // console.log("Msg:", msg);
        // console.log("Signed:", signed);

        return firstValueFrom(this.makeRequest({
            url: `api/getWalletNFTsSecured?msg=${msg}&signedMsg=${signed}`,
            method: BaseService.GET
        }));
    }

    getNFTRawData(hash: string) {
        return this.makeRequest({
            url: `api/getNftRawData?hash=${hash}`,
            method: BaseService.GET
        });
    }
}