<div class="layout-wrapper">
    <div class="testnet-banner">
        <i class="mdi mdi-alert-rhombus-outline"></i>
        This is a Testnet Environment
    </div>

    <div class="layout-row header">
        <div class="child layout-left"
            [ngClass]="{
                'collapsed': !menuExpanded,
                'hide': menuHidden,
                'full-menu': showFullMenu
            }"
        >
            <a routerLink="./">
                <img id="header-logo" class="logo"
                    [src]="menuExpanded
                        ? (currentTheme === 'light'
                            ? 'assets/images/tokenx-logo.png'
                            : 'assets/images/tokenx-logo-dark.png')
                        : (currentTheme === 'light'
                            ? 'assets/images/tx-smb.png'
                            : 'assets/images/tx-smw.png')"
                >
            </a>
        </div>
        
        <app-main-header class="child layout-right" [menuExpanded]="menuExpanded"></app-main-header>
    </div>

    <div class="layout-row body">
        <app-left-nav
            class="child layout-left"
            [ngClass]="{
                'collapsed': !menuExpanded,
                'hide': menuHidden,
                'full-menu': showFullMenu
            }"
            [menuExpanded]="menuExpanded"
        ></app-left-nav>

        <div class="nav-toggle"
            [class.collapsed]="!menuExpanded"
            (click)="toggleMenu()"
        >
            <i class="mdi mdi-chevron-left"></i>
        </div>

        <div
            class="child layout-right app-body"
            [class.full-menu]="showFullMenu"
            [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''"
        >
            <router-outlet #o="outlet"></router-outlet>
        </div>
    </div>
</div>

<div *ngIf="showLoadingMask" class="loading-mask">
    <div class="loading-box">
        <p-progressSpinner></p-progressSpinner><br/>
        Processing...
        <br/><br/>
        this might take a minute
    </div>
</div>

<app-alerts></app-alerts>