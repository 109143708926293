import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OAuthModule } from "angular-oauth2-oidc";

import { reducers } from './store';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./shared/shared.module";
import { AuthGuard } from './auth.guard';
import { UserEffects } from './user/user.effects';
import { LayoutEffects } from "./layout/layout.effects";
import { LeftNavComponent } from './left-nav/left-nav.component';
import { MainHeaderComponent } from './main-header/main-header.component';
import { CreateTokenComponent } from './create-token/create-token.component';
import { WalletComponent } from './wallet/wallet.component';
import { AlertsComponent } from './shared/components/alerts/alerts.component';
import { LoginPageComponent } from './login-page';
import { BlockchainsInfoComponent } from "./blockchains";

import { UserService } from './user/user.service';
import { LayoutService } from './shared/services';
import { TokenService } from './shared/services/token.service';

const APP_SERVICES = [
    UserService,
    LayoutService,
    TokenService
];


@NgModule({
  declarations: [
    AppComponent,
    MainHeaderComponent,
    LeftNavComponent,
    WalletComponent,
    CreateTokenComponent,
    AlertsComponent,
    LoginPageComponent,
    BlockchainsInfoComponent
  ],
  imports: [
    EffectsModule.forRoot([
      UserEffects,
      LayoutEffects
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25 // Retains last 25 states
    }),
    StoreModule.forRoot(reducers),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    OAuthModule.forRoot()
  ],
  providers: [
    AuthGuard,
    ...APP_SERVICES
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
