import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FileUploadModule } from 'ng2-file-upload';

import { PanelMenuModule } from 'primeng/panelmenu';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from "primeng/card";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from "primeng/inputtextarea";
import { DropdownModule } from "primeng/dropdown";
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { PanelModule } from 'primeng/panel';
import { SelectButtonModule } from "primeng/selectbutton";
import { TabViewModule } from "primeng/tabview";
import { AccordionModule } from 'primeng/accordion';
import { TooltipModule } from "primeng/tooltip";
import { DividerModule } from "primeng/divider";
import { CheckboxModule } from "primeng/checkbox";
import { RadioButtonModule } from "primeng/radiobutton";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ChipsModule } from "primeng/chips";
import { InputNumberModule } from "primeng/inputnumber";
import { ProgressBarModule } from "primeng/progressbar";
import { TableModule } from "primeng/table";

const PRIMENG_MODULES = [
    SelectButtonModule,
    PanelMenuModule,
    MenuModule,
    DialogModule,
    InputTextModule,
    InputTextareaModule,
    CardModule,
    ButtonModule,
    DropdownModule,
    AccordionModule,
    ToastModule,
    PanelModule,
    ProgressSpinnerModule,
    TabViewModule,
    TooltipModule,
    DividerModule,
    CheckboxModule,
    RadioButtonModule,
    OverlayPanelModule,
    ChipsModule,
    InputNumberModule,
    ProgressBarModule,
    TableModule
];

import {
    LayoutService,
    TokenService
} from "./services";
import { FileDropzoneComponent } from './components/file-dropzone/file-dropzone.component';
import { LoginComponent } from "./components/login";
import { ThemeSwitcherComponent } from "./components/theme-switcher";


@NgModule({
    declarations: [
        FileDropzoneComponent,
        LoginComponent,
        ThemeSwitcherComponent
    ],
    imports: [
        CommonModule,
        FileUploadModule,
        FormsModule,
        ReactiveFormsModule,
        ...PRIMENG_MODULES
    ],
    exports: [
        CommonModule,
        FormsModule,
        FileDropzoneComponent,
        LoginComponent,
        ThemeSwitcherComponent,
        ReactiveFormsModule,
        ...PRIMENG_MODULES
    ],
    providers: [
        LayoutService,
        TokenService
    ]
})
export class SharedModule {}