<div class="page-body">
    <div class="intro-text">
        Here is a comparison of the blockchains we currently support for minting tokens. This outlines key features such as fees, transaction speeds, and scalability. If you need help choosing the best option for your project or don't see the information you're looking for, feel free to contact our support team for assistance.
    </div>

    <div class="table-con">
        <p-table
            [columns]="cols"
            [value]="chains"
            [scrollable]="true"
        >
            <ng-template pTemplate="header" let-columns>
                <tr class="header-row">
                    <th class="empty" pFrozenColumn></th>

                    <th *ngFor="let col of columns">
                        {{ col.header }}
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-chain let-columns="columns">
                <ng-template #tagTemplate let-body="body">
                    <div class="tag {{body.tag_class}}">
                        {{ body.tag }}
                    </div>

                    {{ body.value }}
                </ng-template>

                <tr class="data-row">
                    <td class="centered title-cell" pFrozenColumn>
                        <div class="chain-logo">
                            <img [src]="'assets/images/blockchains/' + chain.icon">
                        </div>

                        <div class="chain-name">
                            {{ chain.name }}
                        </div>
                    </td>

                    <ng-container *ngFor="let col of columns">
                        <td *ngIf="col.containsTag">
                            <ng-container *ngTemplateOutlet="tagTemplate; context: { body: chain[col.field] }"></ng-container>
                        </td>

                        <td *ngIf="!col.containsTag" class="centered">
                            {{ chain[col.field] }}
                        </td>
                    </ng-container>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
