<div class="main-header">
    <div *ngIf="headerConfig?.containsSubmenu" class="sub-header" [class.expanded]="showMenu">
        <div class="sub-header-text" [class.shown]="!headerConfig.submenuIconOnly">
            {{ headerConfig.title }}
        </div>

        <div class="sub-header-icon" [class.shown]="headerConfig.submenuIconOnly">
            <a routerLink="./">
                <img [src]="themeState
                    ? 'assets/images/tx-smw.png'
                    : 'assets/images/tx-smb.png'"
                >
            </a>
        </div>
    </div>

    <div *ngIf="!headerConfig?.hide" class="banner">
        <span *ngIf="headerConfig.parent" class="main-title">
            <i *ngIf="headerConfig.parent.icon.startsWith('mdi-')" class="mdi {{headerConfig.parent.icon}}"></i>
            <span *ngIf="!headerConfig.parent.icon.startsWith('mdi-')" class="material-symbols-outlined">{{ headerConfig.parent.icon }}</span>
            {{ headerConfig.parent.title }}&nbsp;/&nbsp;
        </span>

        <span class="sub-title">
            <ng-container *ngIf="headerConfig.titleIcon">
                <i *ngIf="headerConfig.titleIcon.startsWith('mdi-')" class="mdi {{headerConfig.titleIcon}}"></i>
                <span *ngIf="!headerConfig.titleIcon.startsWith('mdi-')" class="material-symbols-outlined">{{ headerConfig.titleIcon }}</span>
            </ng-container>

            <img *ngIf="headerConfig.titleImage" [src]="headerConfig.titleImage">

            {{ headerConfig.title }}
        </span>
    </div>

    <!-- <div class="header-right">
        <theme-switcher></theme-switcher>

        <login></login>
    </div> -->

    <div class="mobile-menu-con" (click)="toggleFullMenu()">
        <i class="mdi mdi-menu"></i>
    </div>
</div>
