import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { Store } from '@ngrx/store';
import { Subscription } from "rxjs";
import { filter, distinctUntilChanged } from "rxjs/operators";

import { AppStore } from '../store';
import { DEMO_LIST } from "../shared/utilities/constants";
import { HideSubmenuAction, ShowSubmenuAction, ToggleFullMenuAction } from '../layout/layout.actions';
import { getFullMenuShown, getTheme } from '../layout/layout.selectors';


@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit, OnDestroy {
    @Input() menuExpanded: boolean;

    private subscriptions: Subscription[] = [];

    public headerConfig: any;
    public showMenu: boolean = false;
    public themeState: boolean = false;

    constructor(
        private store: Store<AppStore>,
        private router: Router
    ){}

    ngOnInit(): void {
        this.subscriptions.push(
            this.store.select(getFullMenuShown).subscribe(shown => {
                this.showMenu = shown;
            })
        );

        this.subscriptions.push(
            this.store.select(getTheme).subscribe(theme => {
                this.themeState = (theme === "dark");
            })
        );

        this.updatePageTitle(this.router.url);
        this.subscriptions.push(
            this.router.events
                .pipe(
                    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
                    distinctUntilChanged()
                )
                .subscribe((event: NavigationEnd) => {
                    this.updatePageTitle(event.url);
                })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    updatePageTitle(url: string) {
        let PAGE_TITLES: any[] = [
            {
                page: "/",
                hide: false,
                title: "Tokens",
                titleIcon: "stack_hexagon",
                containsSubmenu: false
            },
            {
                page: "/create_token",
                hide: false,
                parent: {
                    title: "Foundry",
                    icon: "wallet"
                },
                title: "Create Token",
                titleIcon: "",
                containsSubmenu: false
            },
            {
                page: "/demos",
                hide: false,
                title: "Demos",
                titleIcon: "mdi-laptop",
                containsSubmenu: false
            }
        ];

        PAGE_TITLES.push(...DEMO_LIST.map(demo => ({
            page: demo.routerLink,
            hide: false,
            title: demo.name,
            titleIcon: demo.titleIcon,
            containsSubmenu: true,
            submenuIconOnly: demo.submenuOnly
        })));

        for (let i = PAGE_TITLES.length - 1; i >= 0; i--) {
            if (url.includes(PAGE_TITLES[i].page)) {
                this.headerConfig = PAGE_TITLES[i];
                break;
            }
        }

        if (this.headerConfig.containsSubmenu === this.menuExpanded) {
            this.store.dispatch(
                this.headerConfig.containsSubmenu
                    ? ShowSubmenuAction()
                    : HideSubmenuAction()
            );
        }
    }

    toggleFullMenu() {
        this.store.dispatch(ToggleFullMenuAction());
    }
}
