import { createAction } from "@ngrx/store";

export const FractionalizeActionTypes = {
    TOGGLE_SIDE_PANEL: "[Fractionalize] Toggle Side Panel",
    SET_TOKENID: "[Fractionalize] Set TokenId",
    MINT_MASTER: "[Fractionalize] Mint Master Token",
    MINT_MASTER_SUCCESS: "[Fractionalize] Mint Master Token Success",
    MINT_MASTER_FAILURE: "[Fractionalize] Mint Master Token Failure",
    TOKEN_VIEW: "[Fractionalize] View Token",
    TOKEN_VIEW_SUCCESS: "[Fractionalize] View Token Success",
    TOKEN_VIEW_FAILURE: "[Fractionalize] View Token Failure",
    FETCH_TOKEN_STATUS: "[Factionalize] Fetch Token Status",
    FETCH_TOKEN_STATUS_SUCCESS: "[Fractionalize] Fetch Token Status Success",
    FETCH_TOKEN_STATUS_FAILURE: "[Fractionalize] Fetch Token Status Failure",
    FRACTIONALIZE_TOKEN: "[Fractionalize] Fractionalize Token",
    FRACTIONALIZE_TOKEN_SUCCESS: "[Fractionalize] Fractionalize Token Success",
    FRACTIONALIZE_TOKEN_FAILURE: "[Fractionalize] Fractionalize Token Failure",
    REDEEM_FRACTIONS: "[Fractionalize] Redeem Fractions",
    REDEEM_FRACTIONS_SUCCESS: "[Fractionalize] Redeem Fractions Success",
    REDEEM_FRACTIONS_FAILURE: "[Fractionalize] Redeem Fractions Failure"
};

export const ToggleSidePanelAction = createAction(
    FractionalizeActionTypes.TOGGLE_SIDE_PANEL,
    (set?: boolean) => ({ payload: set })
);

export const SetTokenIdAction = createAction(
    FractionalizeActionTypes.SET_TOKENID,
    (id: string) => ({ payload: id })
);

export const MintMasterToken = createAction(
    FractionalizeActionTypes.MINT_MASTER,
    (data: any) => ({ payload: data })
);

export const MintMasterTokenSuccess = createAction(
    FractionalizeActionTypes.MINT_MASTER_SUCCESS,
    (res: any) => ({ payload: res })
);

export const MintMasterTokenFailure = createAction(
    FractionalizeActionTypes.MINT_MASTER_FAILURE,
    (err: string) => ({ payload: err })
);

export const viewPdf = createAction(
    FractionalizeActionTypes.TOKEN_VIEW,
    (tokenId: string) => ({ tokenId: tokenId })
);

export const viewPdfSuccess = createAction(
    FractionalizeActionTypes.TOKEN_VIEW_SUCCESS,
    (data: any) => ({ payload:data })
);

export const viewPdfFail = createAction(
    FractionalizeActionTypes.TOKEN_VIEW_FAILURE,
    (err: any) => ({ payload:err })
);

export const FetchTokenStatusAction = createAction(
    FractionalizeActionTypes.FETCH_TOKEN_STATUS,
    (tokenId: string) => ({ payload: tokenId })
);

export const FetchTokenStatusSuccessAction = createAction(
    FractionalizeActionTypes.FETCH_TOKEN_STATUS_SUCCESS,
    (data: any) => ({ payload: data })
);

export const FetchTokenStatusFailureAction = createAction(
    FractionalizeActionTypes.FETCH_TOKEN_STATUS_FAILURE,
    (err: string) => ({ payload: err })
);

export const FractionalizeTokenAction = createAction(
    FractionalizeActionTypes.FRACTIONALIZE_TOKEN,
    (data: any) => ({ payload: data })
);

export const FractionalizeTokenSuccessAction = createAction(
    FractionalizeActionTypes.FRACTIONALIZE_TOKEN_SUCCESS,
    (res: any) => ({ payload: res })
);

export const FractionalizeTokenFailureAction = createAction(
    FractionalizeActionTypes.FRACTIONALIZE_TOKEN_FAILURE,
    (err: string) => ({ payload: err })
);

export const RedeemFractionsAction = createAction(
    FractionalizeActionTypes.REDEEM_FRACTIONS,
    (id: string, fractions: number) => ({ payload: { id, fractions } })
);

export const RedeemFractionsSuccessAction = createAction(
    FractionalizeActionTypes.REDEEM_FRACTIONS_SUCCESS,
    (res: any) => ({ payload: res })
);

export const RedeemFractionsFailureAction = createAction(
    FractionalizeActionTypes.REDEEM_FRACTIONS_FAILURE,
    (err: string) => ({ payload: err })
);