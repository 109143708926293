export const MOBILE_CUTOFF_WIDTH: number = 640; // in px

export const enum RequestMethod {
    "POST",
    "GET",
    "PUT",
    "PATCH",
    "DELETE"
};

export const enum ResponseType {
    "BLOB" = "blob",
    "ARRAY_BUFFER" = "arraybuffer",
    "TEXT" = "text"
};

export const IPFS_BASE_URL = "https://maroon-like-koala-285.mypinata.cloud/ipfs/";
export const CONTRACT_ABI_JSON = "/contracts/LSTokenX.json";

export class BlockchainModel {
    name: string;
    chainId: string;
    coin: string;
    coin_short: string;
    icon?: string;
    url?: string;
    tokenUrl?: string;
    tokenUrlConfigurable?: string;
    defaultContract?: string;
    selectable: boolean = false;
    public?: boolean;
    coming_soon?: boolean;
    new?: boolean;
    disabled?: boolean;
};

import BLOCKCHAINS_CONFIG from "./configs/blockchains-list.json";
export const BLOCKCHAIN_OPTIONS: BlockchainModel[] = BLOCKCHAINS_CONFIG;
export const DEFAULT_BLOCKCHAIN = "421614";

export function formNFTLink(tokenId: string, chainId: string) {
    let chain = BLOCKCHAIN_OPTIONS.find(option => option.chainId === chainId)
        || BLOCKCHAIN_OPTIONS.filter(chain => chain.selectable)[0];
    return chain.tokenUrl + tokenId;
}

export const DEFAULT_STARTING_POINTS = 1000;

export function toPayload<T> (arg: T) {
    return { payload: arg };
};

import DEMOS_CONFIG from "./configs/demos-list.json";
export const DEMO_LIST: any[] = DEMOS_CONFIG;
